<template>
  <b-navbar
    class="fixed-top"
    toggleable="sm"
    type="dark"
    variant="dark"
    sticky
    role="navigation"
    style="z-index: 9999;"
  >
    <b-navbar-brand href="#" @click="toHome()">
      <img src="../assets/dt-logo2.png" width="90" height="44" alt="DTIP" />
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
    <!--<b-navbar-nav class="ml_rev">-->
    <b-navbar-nav class="ml_rev justify-content-center align-items-center">
        
         <b-nav-item href="/dreamtime">
        {{ $t("message.Dream_time") }}
        </b-nav-item>
      
        &nbsp;&nbsp;
         <!-- <b-nav-item href="/business/investment">
        {{ $t("message.Business") }}
        </b-nav-item> -->
        <b-nav-item-dropdown id="dropdown-right" style="font-size:24px;" right :text="$t('message.Business')"  caret>
          <b-dropdown-item href="/business/investment">{{ $t("message.Business_investment") }}</b-dropdown-item>
          <b-dropdown-item href="/business/oem">{{ $t("message.Business_oem") }}</b-dropdown-item>
        </b-nav-item-dropdown>
        &nbsp;&nbsp;

        <b-nav-item href="/business/gallery">
        {{ $t("message.Image_Gallery") }}
        </b-nav-item>
          &nbsp;&nbsp;

        <b-nav-item href="/store">
        {{ $t("message.Store") }}
        </b-nav-item>

          &nbsp;&nbsp;

         <b-nav-item href="/about/us">
        {{ $t("message.Aboutus") }}
        </b-nav-item>

    </b-navbar-nav>

    <!--<b-collapse id="nav-collapse" is-nav>-->
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto justify-content-center align-items-center">
        <b-nav-item
          href="#"
          v-b-modal.modal-1
          :class="{'d-none':access_token!=''}"
        >{{ $t("message.Login") }}</b-nav-item>

        <b-nav-item
          href="#"
          v-b-modal.modal-2
          :class="{'d-none':access_token!=''}"
        >{{ $t("message.Register") }}</b-nav-item>

        <b-nav-item href="#" @click="toUcenter()" :class="{'d-none':access_token==''}">
          <i class="fas fa-user-alt"></i>
          {{loginname}}
        </b-nav-item>
        <!--<b-nav-item-dropdown id="dropdown-right" right :text="lang"  caret>
          <b-dropdown-item href="/">English</b-dropdown-item>

          <b-dropdown-item href="/cn">中文</b-dropdown-item>
          
          <b-dropdown-item href="/de/category/0">Deutsche</b-dropdown-item>
          
          <b-dropdown-item href="/fr/category/0">Français</b-dropdown-item>
          
          <b-dropdown-item href="/es/category/0">Español</b-dropdown-item>
         
          <b-dropdown-item href="/it/category/0">Italiano</b-dropdown-item>
          
          <b-dropdown-item href="/ru/category/0">Pусский</b-dropdown-item>
          
          <b-dropdown-item href="/tr/category/0">Türk</b-dropdown-item>
          
          <b-dropdown-item href="/pl/category/0">Polskie</b-dropdown-item>
          
          <b-dropdown-item href="/jp/category/0">日本語</b-dropdown-item>
          
          <b-dropdown-item href="/kr/category/0">한국어</b-dropdown-item>
          
          <b-dropdown-item href="/in/category/0">हिंदी</b-dropdown-item>

          <b-dropdown-item href="/vn/category/0">Việt</b-dropdown-item>

          <b-dropdown-item href="/th/category/0">ภาษาไทย</b-dropdown-item>

          <b-dropdown-item href="/my/category/0">Malay</b-dropdown-item>

          <b-dropdown-item href="/id/category/0">Indonesia</b-dropdown-item>

          <b-dropdown-item href="/ph/category/0">Pilipino</b-dropdown-item>

          <b-dropdown-item href="/sa/category/0"> عربي ،</b-dropdown-item>
        </b-nav-item-dropdown>-->
        <div class="nav-item dropdown">
          <button
            class="btn btn-dark"
            type="button"
            id="landropdownMenuButton_cart"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @click="toOrdPre()"
            style="font-size: 28px; color: #9A9DA9;"
          >
            <i class="fas fa-shopping-cart"></i>
            <!-- {{items_cart.length}} -->
          </button>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="landropdownMenuButton_cart"
            :class="{'d-none':access_token==''}"
            @click="toOrdPre()"
          >
            <div class="dropdown-item table-responsive">
              <table class="table table-hover">
                <thead>{{ $t("message.My_Cart") }}</thead>
                <caption>
                  <div class="row">
                    <div class="col">{{ $t("message.CART_TOTAL") }}</div>
                    <div class="col text-right">{{symble_money}}{{total_money}}</div>
                  </div>
                </caption>
                <tbody v-if="items_cart.length >0">
                  <tr v-for="itemCart in items_cart" :key="itemCart">
                    <td
                      class="align-baseline align-middle"
                      @click="toProDet(itemCart.sku.productid)"
                    >
                      <img :src="itemCart.sku.images[0].path" class="rounded float-left whr_as_10" />
                    </td>
                    <td class="align-top align-middle" @click="toProDet(itemCart.sku.productid)">
                      <div>
                        <div class="text-justify">{{itemCart.sku.productname}}</div>
                        <span>{{itemCart.count}}*{{symble_money}}{{itemCart.sku.price}}</span>
                      </div>
                    </td>
                    <td class="align-middle align-right" @click="delShopCartId(itemCart.id)">
                      <i class="far fa-trash-alt"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </b-navbar-nav>
    </b-collapse>
    <b-modal id="modal-1" ref="modal-1" :title="$t('message.Login')" hide-footer>
      <b-form>
        <b-form-group :label="$t('message.UserName')" label-for="input-usrname">
          <b-form-input id="input-usrname" v-model="usrname" required :placeholder="$t('message.UserName')"></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('message.Password')" label-for="input-psw">
          <b-form-input
            id="input-psw"
            v-model="psw"
            type="password"
            required
            :placeholder="$t('message.Password')"
          ></b-form-input>
        </b-form-group>
        <b-button
          type="button"
          variant="outline-dark"
          @click="login(usrname,psw)"
        >{{ $t("message.Login") }}</b-button>
        <small
          class="form-text text-danger"
          :class="{'d-none':rsl_login}"
        >{{$t('message.Usr_Psw_Erro') }}</small>
        <div class="mt-3">
          <p @click="fogpsw()" data-dismiss="modal">{{ $t("message.LOST_PASSWORD") }}</p>
        </div>
      </b-form>
    </b-modal>

    <b-modal id="modal-2" ref="modal-2" :title="$t('message.Register')" hide-footer>
      <form>
        <div class="form-group">
          <label for="exampleInputaUsrName">{{ $t("message.Username_Email_Phone") }}</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputaUsrName"
            aria-describedby="emailHelp"
            :placeholder="$t('message.UserName')"
            v-model="reqUsrName"
            required
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">{{ $t("message.Password") }}</label>
          <input
            type="password"
            class="form-control"
            id="exampleInputPassword1"
           :placeholder="$t('message.Password')"
            v-model="reqPsw"
            required
          />
        </div>
        <div class="form-group">
          <label for="reqemail">{{ $t("message.email") }}</label>
          <input
            type="email"
            class="form-control"
            id="reqemail"
            aria-describedby="emailHelp"
            :placeholder="$t('message.email')"
            v-model="reqEmail"
            required
          />
        </div>
        <button
          type="button"
          class="btn btn-primary"
          @click="req(reqUsrName, reqPsw,reqEmail)"
        >{{ $t("message.Sign_Up") }}</button>
        <small
          class="form-text text-danger"
          :class="{'d-none':rsl}"
          v-if="lan=='en'"
        >{{ reg_msg_en }}</small>
        <small
          class="form-text text-danger"
          :class="{'d-none':rsl}"
          v-if="lan=='cn'"
        >{{reg_msg_cn }}</small>
      </form>
    </b-modal>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HeadSection",
  data() {
    return {
      indexActive:-1,
      usrname: "",
      psw: "",
      reqUsrName: "",
      reqPsw: "",
      reqEmail: "",
      rsl: true,
      rsl_login: true,
      reg_msg_en: "",
      reg_msg_cn: "",
      select_cart_ls: false,
      items_one: [],
      items_two: []
    };
  },

  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      this.rsl = true;
      this.rsl_login = true;
    });
    // this.getNavCategoris();
    // let lg = this.$route.path.split("/")[1];
    // if(lg==''){
    //   if(this.lan!='en'){
    //     localStorage.setItem("lan", 'en');
    //     this.$store.state.lan = 'en';
    //     this.$router.go(0)
    //   }
    // }else if(this.$store.state.lan_array.indexOf(lg)> -1){
    //   if(this.lan!=lg){
    //      localStorage.setItem("lan", lg);
    //      this.$store.state.lan = lg;
    //      this.$router.go(0);
    //   }
    // }
  },
  computed: {
    ...mapGetters([
      "items_cart",
      "total_money",
      "loginname",
      "lang",
      "ismob",
      "iszh",
      "access_token",
      "symble_money",
      "lan",
      "lan_array"
    ])
  },

  updated() {
    if (
      this.access_token != "" &&
      this.items_cart.length == 0 &&
      !this.select_cart_ls
    ) {
      // '#exampleModal'.dismiss
      // this.$router.go(0);
      this.getShopCarLs();
    }
  },

  methods: {
    ...mapActions({
      getNavCategoris(dispatch) {
        dispatch("getNavCategoris", {}).then(data => {
          if (data.result) {
            this.items_one = data.items;
          }
        });
      },
      req(dispatch, reqUsrName, reqPsw, reqEmail) {
        if (reqUsrName === "" || reqPsw === "" || reqEmail === "") {
          return;
        }
        dispatch("req", { reqUsrName, reqPsw, reqEmail }).then(data => {
          this.rsl = data.result;
          if (data.result) {
            //  this.$router.go(0);
            this.login(reqUsrName, reqPsw);
            this.hideModal2();
          } else {
            this.reg_msg_en = data.message_en;
            this.reg_msg_cn = data.message;
          }
        });
      },
      login(dispatch, usrname, psw) {
        if (usrname === "" || psw === "") {
          return;
        }
        this.$store.state.items_cart = [];
        dispatch("login", { usrname, psw }).then(data => {
          this.rsl_login = data.result;
          if (data.result) {
            this.$store.state.loginname = usrname;
            this.$store.state.access_token = data.access_token;
            localStorage.setItem("token", JSON.stringify(data.access_token));
            localStorage.setItem("usrname", JSON.stringify(usrname));
            localStorage.setItem("usrid", JSON.stringify(data.user.id));
            this.$store.state.usr = data.user;

            this.hideModal();
            // this.$store.subscribe((mutation, state) => {
            //     localStorage.setItem('store', JSON.stringify(state));
            // });
          }
        });
      },

      getShopCarLs(dispatch) {
        dispatch("getShopCarLs", {}).then(data => {
          this.select_cart_ls = data.result;
          if (data.result) {
            this.$store.state.items_cart = data.items;
          }
        });
      },
      delShopCartId(dispatch, bussid) {
        dispatch("delShopCartId", { bussid }).then(data => {
          if (data.result) {
            this.getShopCarLs();
          }
        });
      }
    }),
    changeLan(lan) {
      this.$i18n.locale = lan;
      localStorage.setItem("lan", lan);
      this.$store.state.lan = lan;
      this.getNavCategoris();
      this.goSiteHome(lan);
    },

    indexChange(idx){
      this.indexActive = idx;
    },

    goSiteHome(lan){
      let siteHome = lan + "home";
      this.$router.push({
        name: siteHome
      });
    },
    fogpsw() {
      this.$router.push({
        name: "enForgetPsw"
      });
    },
    hideModal() {
      this.$refs["modal-1"].hide();
    },
    hideModal2() {
      this.$refs["modal-2"].hide();
    },

    toProDet(id) {
      let nameProduct = this.lan + 'Product';
      this.$router.push({
        name: nameProduct,
        params: { proid: id }
      });
    },
    toOrdPre() {
      if (this.access_token == "" && this.lan == "cn") {
        this.showToast(this.$t("message.Please_Log_In"));
        return;
      }
      this.$router.push({
        name: "enOrderLsPre"
      });
    },
    toUcenter() {
      if (this.access_token == "" && this.lan == "cn") {
        this.showToast(this.$t("message.Please_Log_In"));
        return;
      }
      this.$router.push({
        name: "enUcenter"
      });
    },
    toComm() {
      this.$router.push({
        name: "encommunity"
      });
    },
    toHome() {
      // let home = this.lan+'home';
      // if(this.lan != 'cn' || this.lan != 'en'){
      //    home = 'enhome';
      // }
      let pt = this.$route.path;
      if(pt==="/"){
        // location.reload();
        this.$router.push({
                path: "/refresh"
              });
      }else{
        this.$router.push({
                path: "/"
              });
      } 
    },

    showToast(msg) {
      this.$bvToast.toast(msg, {
        title: ``,
        toaster: "b-toaster-bottom-center",
        solid: true,
        variant: "danger",
        appendToast: false
      });
    },
    toCateg(id) {
      this.$router.push({
        name: "enCategorySection",
        params: { categoryid: id }
      });
    }
  }
};
</script>
<style scoped>
a {
    color: white;
    font-size: 24px;
}

/* .navbar-dark .nav-link{
    color: white;
}

.navbar-dark .nav-link a{
  font-size: 28px;
} */


</style>


