
<template>
<div>
    <Head-section></Head-section>

<div class="container">
  <b-navbar type="light" variant="faded">
      <b-navbar-brand href="/store">{{ $t("message.dtip_co") }}</b-navbar-brand>
    <b-navbar-nav>
      <b-nav-item-dropdown id="dropdown-right" right :text="$t('message.dtip_category')"  caret>
          <b-dropdown-item v-for="item in categoryis" :key="item.id" :href="'/'+lan+'/category/'+item.id">{{ item.name }}</b-dropdown-item>
        </b-nav-item-dropdown>
        &nbsp;
      
        <!-- <b-nav-item-dropdown id="dropdown-right" right :text="$t('message.dtip_co_1')"  caret>
          <b-dropdown-item href="#">{{ $t("message.dtip_co_1_1") }}</b-dropdown-item>
          <b-dropdown-item href="#">{{ $t("message.dtip_co_1_2") }}</b-dropdown-item>
          <b-dropdown-item href="#">{{ $t("message.dtip_co_1_3") }}</b-dropdown-item>
        </b-nav-item-dropdown>
        &nbsp; -->
        <!-- <b-nav-item-dropdown id="dropdown-right" right :text="$t('message.dtip_co_2')"  caret>
          <b-dropdown-item href="#">{{ $t("message.dtip_co_2_1") }}</b-dropdown-item>
          <b-dropdown-item href="#">{{ $t("message.dtip_co_2_2") }}</b-dropdown-item>
    
        </b-nav-item-dropdown> -->
     
      
    </b-navbar-nav>
  </b-navbar>
</div>
</div>
</template>
<script>
import HeadSection from "./HeadSection.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "StoreHeadSection",
  components: {
    HeadSection
  },
  data() {
    return {
      categoryis: []
    };
  },
  created() {
    // this.getNavCategoris();
    console.log("--------------------------------");
  },
  mounted() {
    this.getNavCategoris();
  },
  computed: mapGetters(["lan", "lan_array"]),

  watch: {
  },
  methods: {
    ...mapActions({
      getNavCategoris(dispatch) {
        dispatch("getNavCategoris", {}).then(data => {
          if (data.result) {
            this.categoryis = data.items;
          }
        });
      }
    })
  },
};
</script>