<style src="../css/head.css">
</style>

<template>
  <div>
    <Head-section></Head-section>
    <!--<div class="container">
      <div v-for="topPrd in topPrds" :key="topPrd.id" class="my-2">
        <a :href="topPrd.redirecturl">
          <b-img-lazy class="img-fluid" :src="topPrd.image" :alt="topPrd.description"></b-img-lazy>
        </a>
      </div>

      <div v-for="adPrd in adPrds" :key="adPrd.id"  class="my-2">
        <a :href="adPrd.redirecturl">
          <b-img-lazy class="img-fluid" :src="adPrd.image" :alt="adPrd.description"></b-img-lazy>
        </a>
      </div>

      <div v-for="prd in prds" :key="prd.id"  class="my-2">
        <a :href="prd.redirecturl">
          <b-img-lazy class="img-fluid" :src="prd.image" :alt="prd.description"></b-img-lazy>
        </a>
      </div>
    </div>-->
    <b-container fluid class="p-4">
      <b-row v-for="topPrd in topPrds" :key="topPrd.id">
          <b-col>
          <a :href="topPrd.redirecturl">
            <b-img-lazy v-bind="mainProps" :src="topPrd.image" :alt="topPrd.description"></b-img-lazy>
          </a>
          </b-col>
      </b-row>

      <b-row v-for="adPrd in adPrds" :key="adPrd.id">
          <b-col>
           <a :href="adPrd.redirecturl">
            <b-img-lazy v-bind="mainProps" :src="adPrd.image" :alt="adPrd.description"></b-img-lazy>
            </a>
          </b-col> 
      </b-row>

      <b-row v-for="prd in prds" :key="prd.id">
          <b-col>
          <a :href="prd.redirecturl">
            <b-img-lazy v-bind="mainProps" :src="prd.image" :alt="prd.description"></b-img-lazy>
          </a>
          </b-col>
      </b-row>

    </b-container>
    <div class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..." variant="success" :class="{'d-none':spin_run}"></b-spinner>
    </div>
  </div>
</template>

<script>
  import HeadSection from "./StoreHeadSection.vue";
  import {
    mapActions,
    mapGetters
  } from "vuex";
  // import * as api from "../api";

  // import axios from "axios";
  export default {
    name: "Home",
    components: {
      HeadSection
    },
    data() {
      return {
        navs: [],
        topPrds: [],
        adPrds: [],
        prds: [],
        spin_run: false,
        info: null,
         mainProps: {
          center: true,
          fluidGrow: true,
          blank: true,
          blankColor: '#bbb',
          class: 'my-1'
        }
      };
    },
    mounted() {
      this.getPrds();
      // axios.get("/api/info/nav/list?location=en&platform=user").then(data => {
      //   this.info = data.data;
      //   if (data.data.result) {
      //     this.navs = data.data.navs;
      //     this.topPrds = data.data.star1;
      //     this.adPrds = data.data.star2;
      //     this.prds = data.data.star3;
      //   }
      // });
    },
    computed: {
      ...mapGetters(["lan"])
    },
    watch: {
      lan(oldlan, newlan) {
        this.getPrds();
      }
    },
    methods: {
      ...mapActions({
        getPrds(dispatch) {
          this.spin_run = false;
          dispatch("getPrds", {}).then(data => {
            this.info = data;
            this.spin_run = data.result;
            if (data.result) {
              this.navs = data.navs;
              this.topPrds = data.star1;
              this.adPrds = data.star2;
              this.prds = data.star3;
            }
          });
        }
      }),
      toProDet(id) {
        let nameProduct = this.lan + 'Product';
        this.$router.replace({
          name: nameProduct,
          params: {
            proid: id
          }
        });
      }
    }
  };
</script>